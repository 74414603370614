<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <!-- Start Page -->
  <div id="page">

    <Footer logged />
    <!-- Page Content -->
    <div class="page-content pb-0">
      <div data-card-height="cover-full" class="card mb-0">
        <div class="card-center text-center pt-4 mt-1">
          <h1 class="font-40 font-800 pb-3">
            <span
              class="
                gradient-highlight
                p-2
                mx-1
                color-white
                scale-box
                d-inline-block
                rounded-s
                border-0
              "
              >PASJOB</span
            >
          </h1>
          <h5 class="mt-n3 font-16 font-600 opacity-30">
            Jobs Seeker & Employer
          </h5>
          <h5 class="pt-1 line-height-s font-400">
            Ayo Segera Buat Akun Anda <br />
            Sebagai Bisnis atau Sebagai Pencari Kerja
          </h5>
          <img src="/images/undraw/a.svg" class="mt-4 mb-4" width="200" />
          <!-- Job Seeker -->
          <router-link
            :to="{
              name: 'SignUp',
              query: { user_type: 'job_seeker', invite_code: inviteCode },
            }"
            class="
              btn btn-m btn-full
              ms-5
              me-5
              mb-2
              rounded-sm
              border-0
              gradient-highlight
              font-600 font-13
            "
          >
            Sebagai Pencari kerja
          </router-link>

          <!-- Employer -->

          <router-link
            :to="{
              name: 'SignUp',
              query: { user_type: 'employer', invite_code: inviteCode },
            }"
            class="
              btn btn-m btn-full
              ms-5
              me-5
              rounded-sm
              border-highlight
              color-highlight
              font-600 font-13
            "
          >
            Sebagai Bisnis
          </router-link>
        </div>
        <!-- Top -->
      </div>
    </div>
    <!-- End Page Content-->

    <!-- Employer -->
  </div>
</template>

<script>
// @ is an alias to /src
import { init_template } from "@/components/menu/index.js";
/*import { preloader, load } from "@/components/script/custom.js";*/

import Footer from "@/components/Footer.vue";
export default {
  name: "CreateConfirm",
  data() {
    return {
      isLoading: true,
      inviteCode: "inviteCode",
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    this.inviteCode = this.$route.query.invite_code;
  },
  components: {Footer},
  methods: {},
};
</script>
